<template>
    <div class="in-report-manage">
        <component :is="views" @goEdit="goEdit"></component>
    </div>
    </template>
    
    <script>
    import sbList from '@/views/SFGL/zdjf/components/zdjfList';
    export default {
        data() {
            return {
                views: sbList
            }
        },
        methods: {
            goEdit(row) {
                console.log(row)
                this.$router.push({
                    path: '/caseManage/caseindex',
                    query: {
                        json: JSON.stringify(row)
                    }
                })
            }
        },
        mounted() {
            this.views = sbList;
        }
    }
    </script>
    